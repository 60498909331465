import React, {useState} from "react";
import {IoIosHourglass, IoMdDownload} from "react-icons/io";

function DownloadPropertyButton({ imageUrl, downloadFunc }) {
    const [loading, setLoading] = useState(false);

    const download = async (url) => {
        setLoading(true); // 로딩 시작
        try {
            // 여기에 다운로드 로직 구현
            // 예시: 파일을 다운로드하거나 데이터를 처리하는 API 호출
            await downloadFunc(url); // 다운로드 함수는 외부에서 구현되어야 합니다.
        } catch (error) {
            console.error('다운로드 중 오류 발생:', error);
        }
        setLoading(false); // 로딩 종료
    }

    return (
        <div>
            {loading ? (
                <IoIosHourglass size={24} /> // 로딩 아이콘
            ) : (
                <IoMdDownload
                    size={24}
                    onClick={() => download(imageUrl)}
                    style={{ cursor: 'pointer' }} // 마우스 포인터를 클릭 가능 상태로 변경
                />
            )}
        </div>
    );
}

export default DownloadPropertyButton;
