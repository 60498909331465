export const basicData = {
  contact_list_cnt: 0,
  id: null,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  deposit: null,
  monthly_rent: null,
  maintenance_cost: null,
  maintenance_cost_str: "",
  address: "",
  road_address: "",
  major_use: "",
  rent_scale: "",
  floor: "",
  available_date: "",
  is_active: null,
  is_verified: null,
  is_contract_completed: false,
  is_contract_completed_by_own: false,
  free_parking_str: "",
  visit_parking: "",
  grade: "",
  status: "",
  last_comment_id: null,
  comment_count: 0,
  marker_id: "",
  toilet: "",
  latitude: null,
  longitude: null,
  recommended_md_name: [],
  available_md_name: [],
  exclusive_area: null,
  contact_area: null,
  area_type: [],
  extra: {
    contact: {
      staffName: "",
      contactList: [],
    },
    bd_hs_available: "",
  },
  room_cnt: null,
  available_md_name_fixed: [],
  recommended_md_name_fixed: [],
  completion_date: "",
  max_floor: null,
  min_floor: null,
  building_name: "",
  elevator_customer: null,
  total_parking: null,
  facing_direction: "",
  description: "",
  updated_by: {
    id: null,
    username: "",
    phone: "",
    email: "",
    is_staff: false,
    is_denied: false,
  },
  household: null,
  file: {
    image_outside: [],
    image_inside: [],
    image_map: [],
    file: [],
  },
  comment: {
    count: 0,
    last_comment: null,
    data: [],
  },
  hospital: [],
  pharmacy: [],
  tags: [],
};
