import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
// src/index.js or App.js

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: apiBaseUrl,
    prepareHeaders: (headers, {endpoint}) => {
        const token = localStorage.getItem("accessToken");
        // Only set the Authorization header for non-uploadFile requests
        if (endpoint !== "uploadFile") {
            headers.set("Authorization", token);
        }
        return headers;
    },
});
const customBaseQuery = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        console.log('Error:', result.error);
    } else {
        // console.log('Response:', result.data);
    }
    return result;
};

export {customBaseQuery};
