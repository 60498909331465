import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {customBaseQuery} from "./api_interceptor";

const regionApi = createApi({
  reducerPath: "regionApi",
  baseQuery: customBaseQuery,
  endpoints(builder) {
    return {
      fetchBasicInfo: builder.query({
        query: ({ address }) => {
          return {
            url: "/region/basic_info",
            params: { address },
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { useFetchBasicInfoQuery } = regionApi;
export { regionApi };
